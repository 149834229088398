// src/errors/LayoutErrorScreen.tsx
import React from "react";
var LayoutErrorScreen = ({
  code,
  message,
  src,
  children
}) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "container"
  }, /* @__PURE__ */ React.createElement("img", {
    src,
    alt: "error"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "code"
  }, code), /* @__PURE__ */ React.createElement("div", {
    className: "message"
  }, message), /* @__PURE__ */ React.createElement("div", {
    className: "footer"
  }, children), /* @__PURE__ */ React.createElement("style", {
    jsx: true
  }, `
        img {
          margin: auto;
        }
        button {
          align-items: center;
          background-color: #2691ea;
          border-radius: 8px;
          border-style: none;
          box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
          box-sizing: border-box;
          color: #fff;
          cursor: pointer;
          display: inline-flex;
          flex-direction: row;
          flex-shrink: 0;
          font-family: "RM Neue", sans-serif;
          font-size: 100%;
          line-height: 1.15;
          margin: 0;
          padding: 10px 21px;
          text-align: center;
          text-transform: none;
          transition: color 0.13s ease-in-out, background 0.13s ease-in-out,
            opacity 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
          user-select: none;
          -webkit-user-select: none;
          touch-action: manipulation;
        }

        button:active {
          background-color: #006ae8;
        }

        button:hover {
          background-color: #1c84ff;
        }

        .container {
          margin: auto;
          padding: 5%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }
        .footer {
          margin-top: 24px;
        }
        .code {
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          color: rgba(0, 0, 0, 0.85);
        }
        .message {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.45);
        }
      `));
};
var LayoutErrorScreen_default = LayoutErrorScreen;

export {
  LayoutErrorScreen_default
};
