import {
  LayoutErrorScreen_default
} from "./chunk-O6GKEUKO.mjs";

// src/errors/unknown.tsx
import React from "react";
var ErrorUnknown = ({
  reset,
  message
}) => {
  return /* @__PURE__ */ React.createElement(LayoutErrorScreen_default, {
    code: "Unknown error",
    message,
    src: "/Unknown.svg"
  }, /* @__PURE__ */ React.createElement("button", {
    onClick: reset
  }, "Try again"));
};
var unknown_default = ErrorUnknown;

export {
  unknown_default
};
